import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/Layout.jsx';
import Title from '../components/Title/Title';
import styled from 'styled-components';
import { headData } from '../mock/data';
import SEO from '../components/SEO/SEO';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Fade from 'react-reveal/Fade';
import { MDBCard, MDBCardTitle, MDBCardGroup, MDBCardBody } from 'mdbreact';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => props.marginbottom};
  margin-top: ${(props) => props.margintop};
`;

const CardTitle = styled(MDBCardTitle)`
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: bold !important;
  @media (max-width: 575px) {
    height: 100px;
    padding-top: 35px;
    text-align: center;
  }
`;

const Group = styled(MDBCardGroup)`
  @media (max-width: 575px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const WebsiteGroup = styled(MDBCardGroup)`
  margin: 0 80px;
  @media (max-width: 768px) {
    margin: 0;
  }
  @media (max-width: 575px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const CTA = styled.a`
  color: #000;
  width: 30%;
  @media (max-width: 992px) {
    width: 55%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

const StyledWebsiteCard = styled(MDBCard)`
  margin-left: 100px !important;
  @media (max-width: 575px) {
    margin-left: 0px !important;
  }
`;

const Services = () => {
  const [loading, setLoading] = useState(true);
  const { image } = headData;
  useEffect(() => {
    setLoading(false);
  }, []);
  if (loading) return null;
  return (
    <Layout paddingbottom buttoncolor border backgroundcolor color>
      <Helmet lang="en" />
      <SEO
        title="Iffert Media | Social Media Marketing & Advertising Services"
        siteUrl="https://www.iffertmedia.com/services"
        image={image}
        description="Our social media marketing company offers social media marketing and advertising, and website design to give you the marketing help your company deserves! See our services to grow..."
      />
      <Title display fontsize title="Our Services" />
      <Fade bottom duration={1000} delay={600} distance="30px">
        <div className="services-wrapper__info">
          <p className="services-wrapper__info-text">
            Businesses are now marketing themselves on social media more than ever before. On
            average, people spend over 2.5 hours a day on social media. If you're not putting
            yourself at the top of their feed with proper social media marketing and advertising
            tactics, the amount of money you're potentially losing is huge!
          </p>
          <p className="services-wrapper__info-text">
            We offer many digital marketing and advertising services to create a package that's
            personalized for you and your business. Our expertise in Facebook and Instagram
            campaigns provides you with the results you've been looking for. Take a look at our
            services below and let us know if you have any questions!
          </p>
        </div>
        <div className="social-section">
          <Title title="Marketing Services" />
          <CardExample />
        </div>
        <Column marginbottom="55px">
          <CTA href="/contact" className="cta-btn cta-btn--contact">
            Grow Your Business
          </CTA>
        </Column>
        <Title title="Website Design" />

        <WebsiteGroup>
          <MDBCard>
            <div className="styled-icon">
              <i className="fa fa-wordpress fa-5x" aria-hidden="true" />
            </div>
            <MDBCardBody>
              <CardTitle style={{ textAlign: 'center' }} tag="h2">
                WordPress Websites
              </CardTitle>
            </MDBCardBody>
          </MDBCard>

          <StyledWebsiteCard>
            <div className="styled-icon">
              <i className="fa fa-list-alt fa-5x" aria-hidden="true" />
            </div>
            <MDBCardBody>
              <CardTitle style={{ textAlign: 'center' }} tag="h2">
                Custom Built Websites
              </CardTitle>
            </MDBCardBody>
          </StyledWebsiteCard>
        </WebsiteGroup>
        <Column margintop="40px">
          <CTA href="/contact" className="cta-btn cta-btn--contact">
            Grow Your Business
          </CTA>
        </Column>
      </Fade>
    </Layout>
  );
};

export default Services;

const CardExample = () => {
  return (
    <Group>
      <MDBCard>
        <div className="styled-icon">
          <i className="fa fa-facebook fa-5x" aria-hidden="true" />
        </div>
        <MDBCardBody>
          <CardTitle tag="h2">Facebook </CardTitle>
        </MDBCardBody>
      </MDBCard>

      <MDBCard>
        <div className="styled-icon">
          <i className="fa fa-instagram fa-5x" aria-hidden="true" />
        </div>
        <MDBCardBody>
          <CardTitle tag="h2">Instagram </CardTitle>
        </MDBCardBody>
      </MDBCard>

      <MDBCard>
        <div className="styled-icon">
          <i className="fa fa-envelope fa-5x" aria-hidden="true" />
        </div>
        <MDBCardBody>
          <CardTitle tag="h2">Email </CardTitle>
        </MDBCardBody>
      </MDBCard>
    </Group>
  );
};
// <GatsbySeo
//   title="Iffert Media | Social Media Marketing & Advertising Services"
//   description="Our social media marketing company offers social media marketing and advertising, and website design to give you the marketing help your company deserves! See our services to grow..."
//   canonical="https://www.iffertmedia.com/"
//   openGraph={{
//     url: 'https://www.iffertmedia.com/services',
//     title: 'Iffert Media | Social Media Marketing & Advertising Services',
//     description:
//       'Our social media marketing company offers social media marketing and advertising, and website design to give you the marketing help your company deserves! See our services to grow...',
//     images: [
//       {
//         url:
//           'https://www.iffertmedia.com/static/ee7180b9430b423ddc2fdbdfe2f76f3f/0dadc/IFFERT-MEDIA.png',

//         alt: 'Iffert Media',
//       },
//     ],
//     type: 'website',
//     site_name: 'Iffert Media | Iffert Media Services',
//   }}
//   twitter={{
//     cardType: 'summary_large_image',
//     title: 'Iffert Media | Social Media Marketing & Advertising',
//     site: 'https://www.iffertmedia.com',
//     images: [
//       {
//         url:
//           'https://www.iffertmedia.com/static/ee7180b9430b423ddc2fdbdfe2f76f3f/0dadc/IFFERT-MEDIA.png',
//         alt: 'Iffert Media',
//       },
//     ],
//     description:
//       'Our social media marketing company offers social media marketing and advertising, and website design to give you the marketing help your company deserves! See our services to grow...',
//   }}
// />
